import React, { FC } from 'react';
import { useField } from 'formik';
import TextControl, { TextControlProps } from './TextControl';

export type TextFieldProps = TextControlProps & {
  name: string;
};

export const TextField: FC<TextFieldProps> = ({ size, ...props }) => {
  const [field, { error, touched }] = useField(props);

  return <TextControl size={size} {...props} {...field} errorText={touched ? error : undefined} />;
};
