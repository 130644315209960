import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import ContactInfo from 'components/ContactInfo';
import { graphql } from 'gatsby';

const ContactPage: React.FC = () => {
  return (
    <Layout id="contact">
      <SEO title="Contact" />
      <ContactInfo />
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
