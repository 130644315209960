import c from 'classnames';
import {
  dismissNotification as dismissNotificationAction,
  setNotification as setNotificationAction,
  NotificationType,
} from '../infrastructure/notifications';
import {
  Form,
  FormikComputedProps,
  FormikConfig,
  FormikProvider,
  FormikHandlers,
  FormikHelpers,
  FormikRegistration,
  FormikState,
  FormikValues,
  useFormik,
} from 'formik';
import React, { useCallback } from 'react';
import { useAppDispatch } from '../infrastructure/store';

type CustomFormikHelpers<Values> = FormikHelpers<Values> & {
  setErrorNotification: (text?: string) => void;
  setSuccessNotification: (text?: string) => void;
  setWarningNotification: (text?: string) => void;
};

type CustomOnSubmit<Values> = (values: Values, formikHelpers: CustomFormikHelpers<Values>) => void | Promise<void>;

export type UseFormReturnType<Values extends FormikValues = FormikValues> = FormikState<Values> &
  FormikComputedProps<Values> &
  CustomFormikHelpers<Values> &
  FormikHandlers &
  FormikRegistration;

export type UseFormType = <Values extends FormikValues = FormikValues>(
  props: Omit<FormikConfig<Values>, 'onSubmit'> & { onSubmit: CustomOnSubmit<Values> }
) => UseFormReturnType<Values>;

const useForm: UseFormType = ({ onSubmit, enableReinitialize = true, ...rest }) => {
  const dispatch = useAppDispatch();

  const setNotification = useCallback(
    (notification?: NotificationType) => {
      if (notification) {
        dispatch(setNotificationAction(notification));
      } else {
        dispatch(dismissNotificationAction());
      }
    },
    [dispatch]
  );

  const setErrorNotification = useCallback(
    (title?: string) => {
      setNotification({ type: 'error', title });
    },
    [setNotification]
  );

  const setWarningNotification = useCallback(
    (title?: string) => {
      setNotification({ type: 'warning', title });
    },
    [setNotification]
  );

  const setSuccessNotification = useCallback(
    (title?: string) => {
      setNotification({ type: 'success', title });
    },
    [setNotification]
  );

  const formik = useFormik({
    enableReinitialize,
    onSubmit: async (values, formikHelpers) => {
      return onSubmit(values, {
        ...formikHelpers,
        setErrorNotification,
        setSuccessNotification,
        setWarningNotification,
      });
    },
    ...rest,
  });

  return { ...formik, setErrorNotification, setSuccessNotification, setWarningNotification };
};

export const FormProvider = <Values extends FormikValues = FormikValues>({
  additionalClass,
  children,
  form,
  asPageContainer,
}: {
  children: React.ReactNode;
  form: UseFormReturnType<Values>;
  asPageContainer?: boolean;
  additionalClass?: string;
}): JSX.Element => {
  const { setErrorNotification, setSuccessNotification, setWarningNotification, ...formik } = form;

  return (
    <FormikProvider value={formik}>
      <Form className={c(additionalClass, asPageContainer && 'page-container')}>{children}</Form>
    </FormikProvider>
  );
};

export default useForm;
